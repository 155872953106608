import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { bff } from '../api/bff.ts';
import authReducer from './slices/auth.ts';

const persistConfig = { key: 'root', storage };

const rootReducer = combineReducers({
  [bff.reducerPath]: bff.reducer,
  auth: persistReducer(persistConfig, authReducer)
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/FLUSH',
          'persist/REHYDRATE',
          'persist/PAUSE',
          'persist/PERSIST',
          'persist/PURGE',
          'persist/REGISTER'
        ]
      }
    }).concat([bff.middleware])
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer> & {};

export type AppDispatch = typeof store.dispatch;
