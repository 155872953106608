import { useAuthentication } from '../auth/RequireAuthentication.tsx';
import { useDispatch } from 'react-redux';
import { resetAll } from '../store/actions.ts';
import { redirectToLogout } from '../auth';
import { useUploadFileMutation } from '../api';
import { useState } from 'react';

const maxFileSizeBytes = 2_000_000;

export const Home = () => {
  const dispatch = useDispatch();
  const profile = useAuthentication();

  const [file, setFile] = useState<File | null>(null);
  const [uploadDocument] = useUploadFileMutation();

  const signOutUser = async () => {
    dispatch(resetAll());
    redirectToLogout();
  };

  const handleFileUpload = async () => {
    if (!file) {
      return;
    }

    if (file.size > maxFileSizeBytes) {
      throw new Error(`File is too big: ${file.size} bytes`);
    }

    await uploadDocument({
      file
    });

    setFile(null);
  };

  return (
    <div>
      <div>Hello, {profile.name}!</div>
      <div>
        <input
          type="file"
          accept="text/csv"
          onChange={(e) => {
            if (e.target.files) {
              setFile(e.target.files[0]);
            }
          }}
        />
        <button type="button" onClick={handleFileUpload} disabled={!file}>
          Upload
        </button>
      </div>
      <div>
        <button type="button" onClick={signOutUser}>
          Logout
        </button>
      </div>
    </div>
  );
};
