import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { styled } from 'styled-components';
import { NotFound } from './NotFound';
import { Layout } from './components/Layout';
import { SignIn, SignInCallback } from './auth/SignIn';
import { RequireAuthentication } from './auth/RequireAuthentication.tsx';
import { Home } from './views/Home.tsx';

const AppContainer = styled.div`
  height: 100%;
`;

export const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<RequireAuthentication />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-in/callback" element={<SignInCallback />} />
      </Route>
    )
  );

  return (
    <AppContainer>
      <RouterProvider router={router} />
    </AppContainer>
  );
};
