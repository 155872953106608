import { bff } from './bff';

export type Token = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
};

export type ExchangeAuthorizationCode = {
  authorizationCode: string;
  state: string;
  redirectUri?: string;
};

const auth = bff.injectEndpoints({
  endpoints: (builder) => ({
    exchangeAuthorizationCode: builder.mutation<Token, ExchangeAuthorizationCode>({
      query: ({ redirectUri, ...body }) => ({
        url: `authorize/token${redirectUri ? `?redirect_uri=${encodeURIComponent(redirectUri)}` : ''}`,
        method: 'POST',
        body: body
      })
    })
  }),
  overrideExisting: false
});

export const { useExchangeAuthorizationCodeMutation } = auth;
