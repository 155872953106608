import { styled } from 'styled-components';
import Env from '../Env.ts';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useExchangeAuthorizationCodeMutation } from '../api/auth.ts';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/slices/auth.ts';

const Layout = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const authRedirectUri = Env.IS_DEV ? 'http://localhost:5173/sign-in/callback' : undefined;

const onSignIn = () => {
  if (Env.IS_DEV) {
    window.location.replace(`/api/authorize?redirect_uri=${authRedirectUri}`);
    return;
  } else {
    window.location.replace('/api/authorize');
    return;
  }
};

export const SignIn = () => {
  // const location = useLocation();
  // const loginMessage =
  //   location.state?.loggedOut === true ? 'You have been signed out.' : 'You are not currently signed in.';

  return (
    <Layout>
      <h1>Sign In</h1>
      {/*<Logo src={logo} alt="logo" />*/}
      {/*<Heading size="m">{loginMessage}</Heading>*/}
      {/*<Button label="Sign In" variant="accent" onClick={onSignIn} />*/}
      <button type="button" onClick={onSignIn}>
        Sign In
      </button>
    </Layout>
  );
};

export const SignInCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const exchangedToken = useRef(false);
  const [exchangeAuthorizationCode, { error, isSuccess, isLoading, data }] = useExchangeAuthorizationCodeMutation();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (!code || !state || exchangedToken.current) return;
    exchangedToken.current = true;

    exchangeAuthorizationCode({
      authorizationCode: code,
      state: state,
      redirectUri: authRedirectUri
    });
  }, [searchParams]);

  useEffect(() => {
    if (!isSuccess) return;

    if (data && data.accessToken) {
      dispatch(setToken(data));
    }
  }, [isSuccess, data]);

  if (isSuccess) {
    navigate('/');
    return null;
  }

  return (
    <Layout>
      {/*<Logo src={logo} alt="logo" />*/}
      {isLoading && !error ? <SigningIn /> : <SignInFailed />}
    </Layout>
  );
};

const SigningIn = () => {
  return (
    <>
      <h1>Signing in...</h1>
      {/*<Spinner />*/}
    </>
  );
};

const SignInFailed = () => {
  return (
    <>
      <h1>Something went wrong</h1>
      <p>
        We were unable to sign you in. <NavLink to={'/sign-in'}>Try to sign in again</NavLink>
      </p>
    </>
  );
};
