import { bff } from './bff';

export type Profile = {
  id: string;
  name: string;
  email: string;
};

const users = bff.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, void>({
      query: () => `profile`
    })
  }),
  overrideExisting: false
});

export const { useGetProfileQuery } = users;
