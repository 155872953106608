import { bff } from './bff';

type UploadFileRequest = {
  file: File;
};

const files = bff.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<void, UploadFileRequest>({
      query: ({ file }) => ({
        url: `uploads`,
        method: 'PUT',
        body: file
      })
    })
  }),
  overrideExisting: false
});

export const { useUploadFileMutation } = files;
